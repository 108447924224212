import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies, ComponentGroups,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG30: '16px',
  REG40: '17px',
  REG50: '20px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '32px',
    h2: '28px',
    h3: '18px',
    h4: '18px',
    h5: '14px',
    h6: '13px',
    'content-large-breakpoint': '18px',
    content: '16px',
    button: '16px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "Cubano Regular"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "Filson Regular"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "Filson Regular"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "Filson Book"',
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
